import * as React from "react"
import ScrollAnimation from 'react-animate-on-scroll';

// Static files
import Youtube from "../../images/podcast-youtube-icon.svg"
import Google from "../../images/podcast-google-icon.svg"
import Spotify from "../../images/podcast-spotify-icon.svg"
import Apple from "../../images/podcast-apple-icon.svg"

const Podcasts = ({ podcasts }) => {
    return (
        <div className="
            w-full 
            bg-blue-darker 
            py-14
            "
        >
            <div className="
                mx-auto
                flex flex-col 
                justify-center
                max-w-screen-2xl px-6 sm:px-16
            ">
                    <h2 className="text-2xl sm:text-5xl text-center text-white">
                        {podcasts.heading}
                    </h2>
                <div className="
                    flex flex-col lg:flex-row
                    justify-center
                    w-full sm:w-[80%]
                    mx-auto
                    mt-16 mb-6
                    gap-y-20
                ">
                    <div className="
                        flex flex-row 
                        justify-around
                        w-full
                    ">
                        <a target="_blank" rel="noreferrer" href={podcasts.podcasturls.youtube} title="Youtube podcast">
                            <ScrollAnimation animateIn="fadeIn" animateOnce>
                                <img className="w-[90px] sm:w-[164px] hover:scale-110 transition-transform" src={Youtube} alt=""/>
                            </ScrollAnimation>
                        </a>
                        <a target="_blank" rel="noreferrer" href={podcasts.podcasturls.google} title="Google podcast">
                            <ScrollAnimation animateIn="fadeIn" delay={300} animateOnce>
                                <img className="w-[90px] sm:w-[164px] hover:scale-110 transition-transform" src={Google} alt=""/>
                            </ScrollAnimation>
                        </a>
                    </div>
                    <div className="
                        flex flex-row 
                        w-full
                        justify-around
                    ">
                        <a target="_blank" rel="noreferrer" href={podcasts.podcasturls.spotify} title="Spotify podcast">
                            <ScrollAnimation animateIn="fadeIn" delay={500} animateOnce>
                                <img className="w-[90px] sm:w-[164px] hover:scale-110 transition-transform" src={Spotify} alt=""/>
                            </ScrollAnimation>
                        </a>
                        <a target="_blank" rel="noreferrer" href={podcasts.podcasturls.apple} title="Apple podcast">
                            <ScrollAnimation animateIn="fadeIn" delay={700} animateOnce>
                                <img className="w-[90px] sm:w-[164px] hover:scale-110 transition-transform" src={Apple} alt=""/>
                            </ScrollAnimation>
                        </a>
                    </div>
                </div>
                <p className="text-lg sm:text-4xl text-center text-white">{podcasts.subheading}</p>
            </div>
        </div>
    )
}

export default Podcasts